<template>
  <CCol sm="12" class="px-0">
    <!--    <pre>{{ previousDate }} | {{ oneYearAgo }}</pre>-->
    <div role="group" class="form-group">
      <vue-pikaday
        v-model="date"
        :options="pikadayOptions"
        class="form-control"
      />
    </div>
  </CCol>
</template>

<script>
import formatNum from "@/utils/formatNum";

export default {
  name: "FilterDate",
  props: {
    previousDate: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      date: null,
    };
  },
  watch: {
    date: function (currentValue) {
      const dateString = `${formatNum.get2digits(
        currentValue.getDate()
      )}/${formatNum.get2digits(
        currentValue.getMonth() + 1
      )}/${currentValue.getFullYear()}`;
      const valueToEmit = {
        str: dateString,
        date: currentValue,
      };
      this.$emit("set-date", valueToEmit);
    },
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    pikadayOptions() {
      return {
        format: "DD/MM/YYYY",
        firstDay: 1,
        i18n: {
          months: [...new Set(this.messages.filters.monthsArray)],
          weekdays: [...new Set(this.messages.filters.weekDaysArray.long)],
          weekdaysShort: [
            ...new Set(this.messages.filters.weekDaysArray.short),
          ],
        },
      };
    },
  },
  methods: {
    getDateObj(value) {
      const dateString = `${formatNum.get2digits(
        value.getDate()
      )}/${formatNum.get2digits(value.getMonth() + 1)}/${value.getFullYear()}`;
      return {
        str: dateString,
        date: value,
      };
    },
  },
  beforeMount() {
    this.date = this.previousDate
      ? new Date(new Date().setFullYear(new Date().getFullYear() - 2))
      : new Date();
    if (this.previousDate) {
      this.$store.dispatch(
        "saveFilterSinceDateValue",
        this.getDateObj(this.date).str
      );
    } else {
      this.$store.dispatch(
        "saveFilterUpToDateValue",
        this.getDateObj(this.date).str
      );
    }
  },
};
</script>

<style scoped></style>
