<template>
  <CCard>
    <CCardHeader class="flex flex--space-between flex--align-items-center">
      <span class="col-xs-12 col-md-9 mb-2 mb-md-0 p-0">
        <strong>{{ messages.tables.results }} </strong>
        <slot name="area"></slot>
        <slot name="period"></slot>
      </span>
      <ExportExcelButton
        :tableId="tableId"
        @export-table="exportTableToExcel()"
      />
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :fields="fields"
        :items="items"
        hover
        outlined
        border
        clickableRows
        :columnFilter="false"
        :tableFilter="{
          label: messages.tables.filter,
          placeholder: `${messages.tables.search} ...`,
        }"
        cleaner
        :noItemsView="{
          noItems: messages.tables.noItems,
          noResults: messages.tables.noResults,
        }"
      >
        <template #thead-top>
          <tr>
            <th class="pl-0" :colspan="fields.length">
              <label class="d-inline-flex flex flex--align-items-center">
                <span class="mfe-2">{{
                  messages.recallCampaignsTable.showCancelled
                }}</span>
                <CSwitch
                  class="mr-1"
                  color="danger"
                  :checked.sync="showCancelled"
                  shape="pill"
                />
              </label>
            </th>
          </tr>
        </template>
        <template #completion="{ item }">
          <td>
            <h4 v-if="item.completion">
              <CBadge :color="getCompletionLevel(item.completion)"
                >{{ getCompletionNumber(item.completion) }}%</CBadge
              >
            </h4>
          </td>
        </template>
        <template #xlsx="{ item }">
          <td>
            <CButton
              color="light"
              @click="downloadCsv(item, $event)"
              ><LogoExcel style="pointer-events: none"
            /></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import ExportExcelButton from "@/components/molecules/ExportExcelButton";
import LogoExcel from "@/components/atoms/LogoExcel";
import TableDataToExcel from "@/helpers/TableDataToExcel";
import formatNum from "@/utils/formatNum";
import langStr from "@/utils/langStr";
import FileSaver from "file-saver";
import Api from "@/services/Api";

export default {
  name: "TableRecallCampaigns",
  components: {
    ExportExcelButton,
    LogoExcel,
  },
  props: {
    dataTable: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      finalData: [],
      tableId: "TableRecallCampaigns",
      showCancelled: false,
      currentAreaParams: null,
    };
  },
  watch: {
    showCancelled: function (current) {
      if (current) {
        this.finalData = [...new Set(this.dataTable)];
      } else {
        this.finalData = [
          ...new Set(this.dataTable.filter((elem) => elem.isCancelled === 0)),
        ];
      }
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getCurrentLang;
    },
    messages() {
      return this.$store.getters.getLangMessages;
    },
    fields() {
      const result = [];
      if (this.finalData && this.finalData.length) {
        const firstRow = this.finalData[0];
        for (let key in firstRow) {
          if (key !== "isCancelled") {
            result.push({
              key: key,
              label: this.messages.recallCampaignsTable[key],
            });
          }
        }
        result.push({
          key: "xlsx",
          label: "",
        });
      }
      return result;
    },
    items() {
      const result = [];
      if (this.finalData) {
        this.finalData.map((rowData, i) => {
          const row = {};
          for (let key in rowData) {
            if (key !== "isCancelled") {
              row[key] = rowData[key];
            }
            if (key === "isCancelled" && rowData.isCancelled === 1) {
              row._classes = "cancelled";
            }
          }
          // row.ref = `ref${i}`;
          result.push(row);
        });
      }
      return result;
    },
    filteredData: {
      get() {
        return this.finalData;
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  methods: {
    getCompletionLevel(num) {
      const n = Number(num);
      if (n < 80) {
        return "low";
      } else if (n < 90) {
        return "medium";
      } else {
        return "high";
      }
    },
    getCompletionNumber(num) {
      const n = Number(num);
      return formatNum.get(n, 2);
    },
    exportTableToExcel() {
      TableDataToExcel(
        this.fields,
        this.items,
        this.messages.recallCampaignsTable.title
      );
    },
    downloadCsv(item, event) {
      const button = event.target;
      button.setAttribute("disabled", "disabled");
      const params = {
        ...this.$store.getters.getCurrentAreaParams,
        languageId: langStr.getId(this.lang),
        campaignCode: item.campaignCode,
      };
      const clearedParams = Object.entries(params).filter(([key, val]) => {
        if (val !== "") return [key, val];
      });
      const paramsStr = Object.entries(params)
        .filter(([key, val]) => {
          if (val !== "") return [key, val];
        })
        .map(([key, val]) => `${key}=${encodeURI(val)}`)
        .join("&");

      const urlApi = process.env['VUE_APP_URL_API'];
      const requestUrl = `${urlApi}warranty/getRecallCampaignDetailReportExcel?${paramsStr}`;

      Api.sendGet(requestUrl).then((response) => {
        const BOM = "\uFEFF";
        const csvData = BOM + response.data.data.fileBody;
        const blob = new Blob([csvData], {
          type: "text/csv;charset=utf-8",
        });
        FileSaver.saveAs(blob, response.data.data.filename);
        setTimeout( function() {
          button.removeAttribute("disabled");
        }, 1500);

      });
    },
  },
  async mounted() {
    await this.dataTable;
    if (this.dataTable) {
      this.finalData = [
        ...new Set(this.dataTable.filter((elem) => elem.isCancelled === 0)),
      ];
    }
  },
};
</script>
<style lang="scss" scoped>
thead {
  th {
    border-color: white !important;
    font-weight: normal;

    label {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
  html:not([dir="rtl"]) .form-check-inline {
    margin-right: 0 !important;
  }
}

$low: #da2840;
// $medium: #f7fe34;
$medium: #fac928;
$high: #60d1b1;

.badge {
  color: #ffffff;
}
.badge-low {
  background: $low;
}

.badge-medium {
  background: $medium;
}

.badge-high {
  background: $high;
}

.btn-light.disabled, .btn-light:disabled {
  color: #4f5d73;
  background-color: #ebedef;
  border-color: #ebedef;
  opacity: 0.5;
}
</style>
