<template>
  <CRow>
    <CCol xl="6">
      <CRow class="flex--align-items-center">
        <CCol xl="3">
          <p class="months-range-legend">
            {{ messages.filters.since }}
          </p>
        </CCol>
        <CCol xl="9">
          <FilterDate :previousDate="true" @set-date="setSinceDate($event)" />
        </CCol>
      </CRow>
    </CCol>
    <CCol xl="6">
      <CRow class="flex--align-items-center">
        <CCol xl="3">
          <p class="months-range-legend">
            {{ messages.filters.upTo }}
          </p>
        </CCol>
        <CCol xl="9">
          <FilterDate :previousDate="false" @set-date="setUpToDate($event)" />
        </CCol>
      </CRow>
    </CCol>
    <div v-if="incorrectPeriod" class="error-message error-message px-3">
      {{ messages.validation.incorrectPeriod }}
    </div>
  </CRow>
</template>

<script>
import FilterDate from "@/components/molecules/FilterDate";

export default {
  name: "FilterDatePeriod",
  components: {
    FilterDate,
  },
  data() {
    return {
      dateSince: null,
      dateUpTo: null,
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    incorrectPeriod() {
      if (this.dateSince && this.dateUpTo)
        return this.dateSince > this.dateUpTo;
      return null;
    },
  },
  methods: {
    setSinceDate(value) {
      this.dateSince = value.date;
      if (!this.incorrectPeriod)
        this.$store.dispatch("saveFilterSinceDateValue", value.str);
      this.$emit("is-incorrect-period", this.incorrectPeriod);
    },
    setUpToDate(value) {
      this.dateUpTo = value.date;
      if (!this.incorrectPeriod)
        this.$store.dispatch("saveFilterUpToDateValue", value.str);
      this.$emit("is-incorrect-period", this.incorrectPeriod);
    },
  },
};
</script>
