<template>
  <div>
    <CForm @submit="submitFilterValues">
      <CCard>
        <CButton
          @click="cardCollapse = !cardCollapse"
          tag="button"
          color="link"
          block
          class="flex flex--space-between text-left shadow-none card-header"
        >
          <h5 class="m-0">Filtros</h5>
          <CIcon :name="`cil-chevron-${cardCollapse ? 'top' : 'bottom'}`" />
        </CButton>
        <CCollapse :show="cardCollapse">
          <CCardBody>
            <CRow v-if="includeFiltersArea">
              <CCol xl="12">
                <label class="filter-label">{{ messages.filters.area }}</label>
                <FiltersAreaCombo :activeElements="activeItems" :all-countries="true" />
                <div v-if="areaError" class="error-message error-message">
                  {{ messages.validation.filterAreaCombo }}
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol xl="6">
                <label class="filter-label">{{
                  messages.filters.campaign
                }}</label>
                <CRow>
                  <CCol xl="6">
                    <FilterCampaign />
                  </CCol>
                  <CCol xl="6">
                    <FilterModel />
                  </CCol>
                </CRow>
              </CCol>
              <CCol xl="6">
                <label class="filter-label">{{
                  messages.filters.period
                }}</label>
                <FilterDatePeriod
                  @is-incorrect-period="isIncorrectPeriod($event)"
                />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" color="primary">Mostrar</CButton>
          </CCardFooter>
        </CCollapse>
      </CCard>
      <CRow v-if="showLoader" class="justify-content-center">
        <Loader
          width="100px"
          height="100px"
          fill-color="#c2c2c2"
          cssClass="fadeIn anim-speed--1s"
        />
      </CRow>
      <CRow v-if="showTable" class="fadeIn anim-speed--2s">
        <CCol sm="12">
          <TableRecallCampaigns :data-table="dataTable">
            <span slot="area"> {{ selectedArea }} {{ selectedArea && ' - ' }} </span>
            <span slot="period">{{ selectedPeriod }}</span>
          </TableRecallCampaigns>
        </CCol>
      </CRow>
    </CForm>
  </div>
</template>

<script>
import FilterCampaign from "@/components/molecules/FilterCampaign";
import FilterModel from "@/components/molecules/FilterModel";
import FiltersAreaCombo from "@/components/organisms/FiltersAreaCombo";
import FilterDatePeriod from "@/components/molecules/FilterDatePeriod";
import Loader from "@/components/atoms/Loader";
import TableRecallCampaigns from "@/components/organisms/TableRecallCampaigns";
import Api from "@/services/Api";
import getAreaName from "@/helpers/getAreaName";

export default {
  name: "RecallCampaigns",
  components: {
    TableRecallCampaigns,
    FiltersAreaCombo,
    FilterDatePeriod,
    FilterCampaign,
    FilterModel,
    Loader,
  },
  data() {
    return {
      cardCollapse: true,
      activeItems: ["countries", "regionals", "aspms", "concessions"],
      areaError: false,
      incorrectPeriod: null,
      showLoader: true,
      showTable: false,
      sinceDateSlot: null,
      upToDateSlot: null,
      dataTable: null,
      selectedArea: null,
      selectedPeriod: null,
    };
  },
  computed: {
    lang() {
      return String(this.$store.getters.getCurrentLang);
    },
    messages() {
      return this.$store.getters.getLangMessages;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
    includeFiltersArea() {
      return this.$store.getters.getCurrentUserProfile < 20;
    },
  },
  methods: {
    isIncorrectPeriod(value) {
      this.incorrectPeriod = value;
    },
    makeDefaultQuery() {
      const areaParam = this.includeFiltersArea
        ? { countryCode: this.user.countryCode }
        : { concessionCode: this.user.concessionCode };
      const defaults = {
        ...areaParam,
        campaignType: this.$store.getters.getFilterCampaignValue,
        campaignModel: this.$store.getters.getFilterModelValue,
        sinceDate: this.$store.getters.getFilterSinceDateValue,
        upToDate: this.$store.getters.getFilterUpToDateValue,
      };
      // Saving the area param in the Store to use in Download CSV request
      this.$store.dispatch("saveCurrentAreaParams", areaParam);

      this.makeApiQuery(defaults);
    },
    submitFilterValues(ev) {
      ev.preventDefault();
      const areaParam = this.includeFiltersArea
        ? this.$store.getters.getFiltersAreaComboParams
        : { concessionCode: this.user.concessionCode };
      let areaValidated = false;
      if (!areaParam ) {
        this.areaError = true;
      } else {
        this.areaError = false;
        areaValidated = true;
        // Saving the area param in the Store to use in Download CSV request
        this.$store.dispatch("saveCurrentAreaParams", areaParam);
      }

      if (areaValidated && !this.incorrectPeriod) {
        // Do something
        const sinceDate = this.$store.getters.getFilterSinceDateValue;
        const upToDate = this.$store.getters.getFilterUpToDateValue;
        const params = {
          ...areaParam,
          campaignType: this.$store.getters.getFilterCampaignValue,
          campaignModel: this.$store.getters.getFilterModelValue,
          sinceDate: this.$store.getters.getFilterSinceDateValue,
          upToDate: this.$store.getters.getFilterUpToDateValue,
        };
        this.makeApiQuery(params);
      }
    },
    makeApiQuery(params) {
      this.showLoader = true;
      this.showTable = false;
      this.setTableTitle(params);

      Api.sendPost("warranty/recallCampaignReport", params).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            // Hide the loader and sow the table
            this.showLoader = false;
            this.showTable = true;
            // Set the processed data variable to send to the DataTable component
            this.dataTable = response.data.data;
          }
        }
      });
    },
    setTableTitle(params) {
      this.selectedArea = getAreaName(params, this.messages);
      const sinceDate = params.sinceDate;
      const upToDate = params.upToDate;
      this.selectedPeriod = sinceDate === upToDate ? sinceDate : `de ${sinceDate} a ${upToDate}`;
    },
  },
  mounted() {
    this.makeDefaultQuery();
  },
};
</script>

<style></style>
