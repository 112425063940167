<template>
  <CSelect :options="options" :value.sync="selectedOption" @change="setValue" />
</template>

<script>
import Api from "@/services/Api";

export default {
  name: "FilterModel",
  data() {
    return {
      options: null,
      selectedOption: null,
    };
  },
  methods: {
    setValue() {
      this.$store.dispatch("saveFilterModelValue", {
        value: this.selectedOption,
      });
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getCurrentLang;
    },
    messages() {
      return this.$store.getters.getLangMessages;
    },
  },
  created() {
    const queryUrl = "sys/filter/campaignModels";
    Api.sendGet(queryUrl).then((response) => {
      this.options = [{ value: null, label: "Todos" }, ...response.data.data];
      this.selectedOption = this.options[0].value;
      this.setValue();
    });
  },
};
</script>
