import store from "@/store/index";
<template>
  <CButton
    color="light"
    class="gtm-tag-export"
    @click="$emit('export-table', tableId)"
    :disabled="buttonStatus"
  >
    {{ messages.tables.export.toUpperCase() }}
    <LogoExcel />
    <span class="loader-container" v-if="buttonStatus">
      <Loader
        width="20px"
        height="20px"
        fill-color="#fff"
        cssClass="fadeIn anim-speed--1s"
      />
    </span>
  </CButton>
</template>

<script>
import Loader from "@/components/atoms/Loader";
import LogoExcel from "@/components/atoms/LogoExcel";
export default {
  name: "ExportExcel",
  components: {
    Loader,
    LogoExcel,
  },
  data() {
    return {
      messages: null,
    };
  },
  computed: {
    buttonStatus() {
      return this.$store.getters.getExcelButtonStatus;
    },
  },
  props: {
    tableId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.messages = this.$store.getters.getLangMessages;
  },
};
</script>

<style lang="scss" scoped>
.loader-container {
  display: inline-block;
  overflow: hidden;
  height: 16px;
  margin: 0 -6px 0 6px;
}
.btn {
  &.disabled,
  &:disabled {
    cursor: not-allowed;
  }
}
</style>
