var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[_c('CCardHeader',{staticClass:"flex flex--space-between flex--align-items-center"},[_c('span',{staticClass:"col-xs-12 col-md-9 mb-2 mb-md-0 p-0"},[_c('strong',[_vm._v(_vm._s(_vm.messages.tables.results)+" ")]),_vm._t("area"),_vm._t("period")],2),_c('ExportExcelButton',{attrs:{"tableId":_vm.tableId},on:{"export-table":function($event){return _vm.exportTableToExcel()}}})],1),_c('CCardBody',[_c('CDataTable',{attrs:{"fields":_vm.fields,"items":_vm.items,"hover":"","outlined":"","border":"","clickableRows":"","columnFilter":false,"tableFilter":{
        label: _vm.messages.tables.filter,
        placeholder: `${_vm.messages.tables.search} ...`,
      },"cleaner":"","noItemsView":{
        noItems: _vm.messages.tables.noItems,
        noResults: _vm.messages.tables.noResults,
      }},scopedSlots:_vm._u([{key:"thead-top",fn:function(){return [_c('tr',[_c('th',{staticClass:"pl-0",attrs:{"colspan":_vm.fields.length}},[_c('label',{staticClass:"d-inline-flex flex flex--align-items-center"},[_c('span',{staticClass:"mfe-2"},[_vm._v(_vm._s(_vm.messages.recallCampaignsTable.showCancelled))]),_c('CSwitch',{staticClass:"mr-1",attrs:{"color":"danger","checked":_vm.showCancelled,"shape":"pill"},on:{"update:checked":function($event){_vm.showCancelled=$event}}})],1)])])]},proxy:true},{key:"completion",fn:function({ item }){return [_c('td',[(item.completion)?_c('h4',[_c('CBadge',{attrs:{"color":_vm.getCompletionLevel(item.completion)}},[_vm._v(_vm._s(_vm.getCompletionNumber(item.completion))+"%")])],1):_vm._e()])]}},{key:"xlsx",fn:function({ item }){return [_c('td',[_c('CButton',{attrs:{"color":"light"},on:{"click":function($event){return _vm.downloadCsv(item, $event)}}},[_c('LogoExcel',{staticStyle:{"pointer-events":"none"}})],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }